<template>
<div style="width:100%">
  <v-sheet :color="colores.primario" style="padding:1px" rounded>
    <v-card flat>
      <v-toolbar dense flat><span :style="[{'color': colores.primario}, {'font-size': '20px' }]"><b>CONFIRMACIÓN DE DATOS</b></span></v-toolbar>
      <v-card-text class="pt-0">
        <v-row no-gutters>
          <v-col cols="12">
            <v-text-field v-model="form_confirmar.correo" :rules="[rules.requerido, rules.confirmacionEmail]" :error-messages="messagesError" :color="colores.primario" label="CORREO ELECTRÓNICO DE NOTIFICACIÓN *"></v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12" md="6" class="pr-1">
            <v-text-field v-model="form_confirmar.codigo_sfc" :color="colores.primario" label="CÓDIGO SFC *" disabled></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field v-model="form_confirmar.codigo_dcf" :rules="[rules.numeroGuion]" maxlength="20" counter="20" :color="colores.primario" label="CÓDIGO DCF"></v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12" md="6" class="pr-1">
            <v-text-field v-model="form_confirmar.codigo_tutela" :rules="[rules.numeroGuion]" maxlength="15" counter="15" :color="colores.primario" label="CÓDIGO TUTELA"></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field v-model="form_confirmar.codigo_interno" :rules="[rules.alfanumerico]" maxlength="15" counter="15" :color="colores.primario" label="CÓDIGO INTERNO TT/T24"></v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12" md="6">
            <v-checkbox v-model="form_confirmar.proteccion_dp" label="PROTECCIÓN DATOS PERSONALES"></v-checkbox>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-sheet>
</div>
</template>

<script>

export default {
  name: 'confirmacion_datosComponent',
  components: {
  },
  props: ['colores', 'rules', 'form_confirmar', 'messagesError'],
  data: () => ({
    //
  })
}
</script>
